import Service from "../Service";

const resource = "disinfectedselection/";

export default {
    save(obj, requestID) {
        console.log('service ', obj);
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    pagination(obj, requestID) {
        return Service.post(resource + "paginationD", obj, {
            params: { requestID: requestID },
        });
    },

    list(obj, requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID: requestID },
        });
    },

    lotreceptioncalibratedlist(requestID) {
        return Service.post(resource + "lotreceptioncalibratedlist", {}, {
            params: { requestID: requestID },
        });
    },

    reportpdf(dtr, requestID) {
        return Service.post(resource + "reportpdf", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },

};