<template>
	<v-card class="m-0 p-0">
		<v-col cols="12" lg="12" md="12" style="display: contents;">
			<template style="margin-top: 10px;">
				<s-crud
					:filter="filter"
					:config="config"
					search-input
					remove
					title="Lavado y desinfección"
					@rowSelected="rowSelected($event)"
					@save="save($event)"
					add
				>
					<template v-slot:filter>
						<v-container>
							<v-row justify="center">
								<v-col lg="3">
									<s-date label="Fecha" v-model="filter.DateInitial"></s-date>
								</v-col>
							</v-row>
						</v-container>
					</template>
					
					<template v-slot="props">
						<v-container>
							<v-row>
								<v-col>
									<!-- <s-time
										label="Hora"
										v-model="props.item.DssHour"
										visible
									></s-time> -->
									<s-date-time
										label="Fecha y Hora"
										v-model="props.item.DssHour"
										 
									></s-date-time>
								</v-col>
								<v-col>
									<s-select
										label="Lote"
										:items="itemsLotCalibrated"
										item-value="RcfID"
										item-text="RcfName"
										returnObject
										v-model="RcfObject"
										clearable
										autocomplete
									></s-select>
								</v-col>
								<v-col>
									<s-text
										label="Cantidad de jabas"
										v-model="props.item.DssQuantityJabas"
										number
									></s-text>
								</v-col>
								<v-col>
									<s-select-definition
										v-model="props.item.TypeDisinfectant"
										label="Tipo desinfectante"
										:def="1329">
									</s-select-definition>
								</v-col>
								<v-col>
									<s-select-definition
										label="Lugar de muestra"
										v-model="props.item.TypeSampleLocation"
										:def="1373"
									></s-select-definition>
									<!-- <s-select-definition
										v-model="disinfected.TypeSampleLocation"
										label="Lugar de muestra"
										:def="1373"
									>
									</s-select-definition> -->
									<!-- <s-select
										label="Lugar Muestra"
										item-value="SlsID"
										item-text="SlsName"
										:items="itemsSamplingLocationSelection"
									></s-select> -->
									<!-- <s-select
										label="Lugar de muestra"
										full
										item-value="SlsID"
										item-text="SlsName"
										:items="itemsSamplingLocationSelection"
										v-model="props.item.SlsID"
									></s-select> -->
								</v-col>
								<v-col>
									<s-text
										v-model="props.item.DssDisinfectantDetergent"
										label="Desin.(ml)/Deter.(ml)"
									></s-text>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<s-text
										v-model="props.item.DssRepowering"
										label="Repotenciado"
									></s-text>
								</v-col>
								<v-col>
									<s-text
										v-model="props.item.DssConcentration"
										label="Con. de des.(ppm)/Deter. %"
									></s-text>
								</v-col>
								<v-col>
									<s-text
										v-model="props.item.DssDiveTime"
										label="Tiempo Inmersión (min)"
									></s-text>
								</v-col>
								<v-col>
									<s-select-definition
										v-model="props.item.TypeWaterLevel"
										label="Nivel de agua"
										:def="1331">
									</s-select-definition>
								</v-col>
								<v-col>
									<s-select-definition
										v-model="props.item.TypeCorrelativeAction"
										label="Acción Correctiva"
										:def="1330">
									</s-select-definition>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<s-textarea
										v-model="props.item.DssObservation"
										label="Observaciones"
									></s-textarea>
								</v-col>
							</v-row>
						</v-container>
					</template>

					<!-- <template v-slot:accion="{ row }">
						<v-btn
							x-small
							@click="openDialogDisinfected(row)"
						>
							<i class="fas fa-pump-medical"></i>
						</v-btn>
					</template> -->

					<template v-slot:options>
						<v-tooltip bottom="">
							<template v-slot:activator="{ on }">
								<v-btn
								v-on="on"
								text
								black 
								@click="openDialogDamageJaba()"
								small
								>
									<i class="fas fa-pallet"></i>
								</v-btn>
							</template>
							<span>Agregar Jabas dañadas</span>
						</v-tooltip>

						<v-tooltip bottom="">
							<template v-slot:activator="{ on }">
								<v-btn
								v-on="on"
								text
								black 
								@click="openDialogFilterReport()"
								small
								>
									<i class="fas fa-download"></i>
								</v-btn>
							</template>
							<span>Descargar Reporte</span>
						</v-tooltip>
					</template>

				</s-crud>
			</template>
		</v-col>

		<v-dialog
			v-if="dialogFilterReport"
			v-model="dialogFilterReport"
			width="400"
		>
			
			<v-card>
				<s-toolbar
					label="Descarga Reporte"
					close
					@close="dialogFilterReport = false"
					pdf
					@pdf="printReportPdf()"
					color="#BAB6B5"
					dark
				></s-toolbar>
				<v-container>
					<v-row>
						<v-col>
							<s-date
								label="Fecha"
								v-model="filterReport.DateInitial"
							></s-date>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<v-dialog
			v-model="dialogDisinfected"
			width="1200"
		>
			<v-card>
				<s-toolbar
					label="lavado y desinfección"
					:add="isEdit"
					@add="addDisinfected()"
					save
					close
					@close="closeDialogDisinfected()"
					color="#BAB6B5"
					dark
					@save="save()"
				></s-toolbar>
				<v-container>
					<v-row>
						<v-col>
							<s-time
								label="Hora"
								v-model="disinfected.DssHour"
							></s-time>
						</v-col>
						<v-col>
							<s-select-definition
								v-model="disinfected.TypeDisinfectant"
								label="Tipo desinfectante"
								:def="1329">
							</s-select-definition>
						</v-col>
						<v-col>
							<!-- <s-select-definition
								v-model="disinfected.TypeSampleLocation"
								label="Lugar de muestra"
								:def="1373"
							>
							</s-select-definition> -->
							<!-- <s-select
								label="Lugar Muestra"
								item-value="SlsID"
								item-text="SlsName"
								:items="itemsSamplingLocationSelection"
							></s-select> -->
							<s-select
								label="Lugar de muestra"
								full
								item-value="SlsID"
								item-text="SlsName"
								:items="itemsSamplingLocationSelection"
								v-model="disinfected.SlsID"
							></s-select>
						</v-col>
						<v-col>
							<s-text
								v-model="disinfected.DssDisinfectantDetergent"
								label="Desin.(ml)/Deter.(ml)"
							></s-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<s-text
								v-model="disinfected.DssRepowering"
								label="Repotenciado"
							></s-text>
						</v-col>
						<v-col>
							<s-text
								v-model="disinfected.DssConcentration"
								label="Con. de des.(ppm)/Deter. %"
							></s-text>
						</v-col>
						<v-col>
							<s-text
								v-model="disinfected.DssDiveTime"
								label="Tiempo Inmersión (min)"
							></s-text>
						</v-col>
						<v-col>
							<s-select-definition
								v-model="disinfected.TypeWaterLevel"
								label="Nivel de agua"
								:def="1331">
							</s-select-definition>
						</v-col>
						<v-col>
							<s-select-definition
								v-model="disinfected.TypeCorrelativeAction"
								label="Acción Correctiva"
								:def="1330">
							</s-select-definition>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<s-textarea
								v-model="disinfected.DssObservation"
								label="Observaciones"
							></s-textarea>
						</v-col>
					</v-row>
				</v-container>
				<v-container>
					<v-row>
						<v-col>
							<v-data-table
								:headers="headers"
								:items="disinfections"
							>
								<template v-slot:item.accion="{item}">
									<v-btn
										x-small
										color="warning"
										@click="editDisinfected(item)"
									>
										<i class="fas fa-edit"></i>
									</v-btn>
									
									<v-btn
										x-small
										color="error"
										@click="deleteDisinfected(item)"
									>
										<i class="fas fa-trash"></i>
									</v-btn>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<v-dialog
			v-model="dialogDamageJaba"
			width="800"
		>
			<damage-jaba @closeDialogDamageJaba="closeDialogDamageJaba"></damage-jaba>
		</v-dialog>

	</v-card>
</template>

<script>
	import _sDisinfectedSelectionService from "@/services/FrozenProduction/FrzDisinfectedSelectionService";
	import _sSamplingLocationSelectionService from "@/services/FrozenProduction/SamplingLocationSelectionService";
	import _sWashSelectionService from "@/services/FrozenProduction/FrzWashSelectionService";
	import DamageJaba from '../DamageJaba/DamageJaba.vue';

	export default {
		components: { 
			DamageJaba 
		},

		data: () => ({
			itemsSamplingLocationSelection: [],
			filter: {},
			config: {
				service: _sDisinfectedSelectionService,
				model: {
					DssID: "ID",
					accion: "string"
					//DssHour: "time"
				},
				headers: [
					{ text: "ID", value: "DssID" },
					{ text: "Lugar Muestra", value: "TypeSampleLocationName"},
					{ text: "Tipo Desinfectante", value: "TypeDisinfectantName" },
					{ text: "Hora", value: "DssHour" },
					{ text: "N° Jabas", value: "DssQuantityJabas"},
					{ text: "Lote", value: "Yuliano"},
					{ text: "Desinfec.", value: "DssDisinfectantDetergent"},
					{ text: "Repotenciado", value: "DssRepowering",},
					{ text: "Concentración", value: "DssConcentration" },
					{ text: "Tiempo Inmersión", value: "DssDiveTime", },
					{ text: "Nivel de agua", value: "TypeWaterLevelName",  },
					{ text: "Acción Correctiva", value: "TypeCorrelativeActionName", },
					{ text: "Responsable", value: "DdsResponsible",},
				],
			},
			lots: [],
			dialogDisinfected: false,
			disinfected: {},
			rowSelect: {},
			disinfections: [],
			headers:[
				{ text: "Acción", value: "accion", width: 100 },
				/* { text: "ID", value: "DssID", width: 100 }, */
				{ text: "Hora", value: "DssHour", width: 100 },
				{ text: "Tipo Desinfectante", value: "TypeDisinfectantName", width: 100 },
				{ text: "Lugar Muestra", value: "TypeSampleLocationName", width: 100 },
				{ text: "Desinfectante/Detergente", value: "DssDisinfectantDetergent", width: 100 },
				{ text: "Repotenciado", value: "DssRepowering", width: 100 },
				{ text: "Concentración", value: "DssConcentration", width: 100 },
				{ text: "Tiempo Inmersión", value: "DssDiveTime", width: 100 },
				{ text: "Nivel de agua", value: "TypeWaterLevelName", width: 100 },
				{ text: "Acción Correctiva", value: "TypeCorrelativeActionName", width: 100 },
				{ text: "Observaciones", value: "DssObservation", width: 100 },
			],
			isEdit: false,
			dialogDamageJaba: false,
			itIsDisinfected: false,
			filterReport: {},
			dialogFilterReport: false,
			itemsLotCalibrated: [],
			RcfObject: {}
		}),


		methods: {
			getItemsLotCalibrated()
			{
				let obj = {
					DateInitial : new Date()
				}
				_sWashSelectionService
				.lotcalibratedtoday(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.itemsLotCalibrated = resp.data;
                        console.log("🚀 ~ file: WashSelection.vue ~ line 410 ~ resp.data", resp.data)
					}
				})
			},

			openDialogFilterReport()
			{
				this.dialogFilterReport = true;
			},

			printReportPdf()
			{
				this.filterReport = this.rowSelect;
				
				_sDisinfectedSelectionService
				.reportpdf(this.filterReport, this.$fun.getUserID())
				.then((r) => {
					this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, this.$fun.getUserID());
				})
				.catch((e) => {
					this.$fun.alert("No hay Datos", "warning");
				});
			},
			

			Initialize()
			{
				_sDisinfectedSelectionService
				.list(this.rowSelect, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.disinfections = resp.data;
						if(this.disinfections.length > 0)
						{
							this.itIsDisinfected = true;
						}
						else
						{
							this.itIsDisinfected = false;
						}
					}
				});
			},

			getSamplingLocationSelection()
			{
				_sSamplingLocationSelectionService
				.list(this.rowSelect, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.itemsSamplingLocationSelection =  resp.data;
					}
				})
			},

			openDialogDisinfected(row)
			{
				this.Initialize();
				this.disinfected.DssDisinfectantDetergent = "";
				this.disinfected.DssRepowering = "";
				this.disinfected.DssConcentration = "";
				this.disinfected.DssDiveTime = "";
				this.disinfected.DssObservation = "";
				this.disinfected.DssID = 0;
				this.dialogDisinfected = true;
				this.isEdit = false;
				
			},

			closeDialogDisinfected()
			{
				this.dialogDisinfected = false;
			},

			save(item)
			{
				
				if(item.itIsDisinfected)
				{
					this.$fun.alert("El pallet ya fue desinfectado", "warning");
					return;
				}

				item.FltID = this.RcfObject.FltID;
				item.RcfID = this.RcfObject.RcfID;
				item.PrsID = this.$fun.getUserID();

				item.save();

				/* this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r => {
					if(r.value)
					{
						this.disinfected.FltID 			= this.rowSelect.FltID;
						this.disinfected.LcsID 			= this.rowSelect.LcsID;
						this.disinfected.RcfID 			= this.rowSelect.RcfID;
						this.disinfected.PrsID 			= this.$fun.getUserID();
						this.disinfected.SecStatus 		= 1;
						this.disinfected.UsrCreateID	= this.$fun.getUserID();
						this.disinfected.UsrUpdateID	= this.$fun.getUserID();
						this.disinfected.DssQuantityJabas	= this.rowSelect.LcsAmountSupply;
						
						_sDisinfectedSelectionService
						.save(this.disinfected, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Agregado correctamente", "success");
								this.Initialize();
							}
						})
					}
				}) */
				
			},

			addDisinfected()
			{
				this.disinfected.DssDisinfectantDetergent = "";
				this.disinfected.DssRepowering = "";
				this.disinfected.DssConcentration = "";
				this.disinfected.DssDiveTime = "";
				this.disinfected.DssObservation = "";
				this.disinfected.DssID = 0;
				this.isEdit = false;
			},

			editDisinfected(item)
			{
				this.disinfected = item;
				this.isEdit = true;
			},

			deleteDisinfected(item)
			{
				this.$fun.alert("¿Seguro de eliminar?", "question")
				.then(r => {
					if(r.value)
					{
						item.SecStatus = 0;
						item.UsrUpdateID = this.$fun.getUserID();
						_sDisinfectedSelectionService
						.save(item, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Actualizado correctamente", "success");
								this.Initialize();
							}
						})
					}
				})
				
			},

			rowSelected(val) {
				if(val.length > 0){
					this.rowSelect = val[0];
                    //this.Initialize();
					//this.getSamplingLocationSelection();
				}
			},

			openDialogDamageJaba()
			{
				this.dialogDamageJaba = true;
			},

			closeDialogDamageJaba()
			{
				this.dialogDamageJaba = false;
			}

		},

		mounted () {
			this.getItemsLotCalibrated();
		},
	};
</script>

<style></style>
